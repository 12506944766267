const rawConsoleLog = console.log;
const rawConsoleError = console.error;
const rawConsoleInfo = console.info;

// 客户端生产环境
const isProductionInBrowser = process.env.STAGING_ENV === 'production' && typeof window !== 'undefined';

console.log = function () {
  if (isProductionInBrowser) return;
  rawConsoleLog.apply(null, arguments);
};

console.error = function () {
  if (isProductionInBrowser) return;
  rawConsoleError.apply(null, arguments);
};

console.info = function () {
  if (isProductionInBrowser) return;
  rawConsoleInfo.apply(null, arguments);
};
