import { GET, POST } from 'src/utils/request';
import { SALES_REPORT_API_PREFIX } from 'src/consts';

export function queryReportPreference() {
  return GET(`${SALES_REPORT_API_PREFIX}/v2/report_preference`);
}

export function updateReportPreference(data) {
  return POST(`${SALES_REPORT_API_PREFIX}/v2/report_preference`, data);
}

export function createUpdateReportDiscountCategory(data) {
  /**
   * data: {
   *   discount_categories: { id, name, foreign_name, description }[],
   *   discount_category_mappings: {discount_category_id, discount_id}[]
   * }
   */
  return POST(`${SALES_REPORT_API_PREFIX}/v2/report_preference/discount_category`, data);
}
