import { isNullOrUndefined } from './utils';

export const DEFAULT_DELIMITER = '.';

export function getChangedEntry(obj, { prefix = '', delimiter = DEFAULT_DELIMITER } = {}) {
  const firstKey = Object.keys(obj)[0];
  const value = obj[firstKey];
  prefix = prefix ? `${prefix}${delimiter}${firstKey}` : firstKey;
  if ({}.toString.call(value) !== '[object Object]')
    return {
      key: prefix,
      value,
      delimiter,
    };
  return getChangedEntry(value, { prefix, delimiter });
}

/**
 * 获取对象指定路径的字段
 * @param {Object} srcObject 原始对象
 * @param {Array<string>} namePath 字段路径
 * @returns any
 */
export function getFieldValueByNamePath(srcObject, namePath) {
  if (!srcObject || !namePath) return undefined;

  let fieldValue = srcObject;
  let i = 0;
  try {
    while (i < namePath.length) {
      fieldValue = fieldValue?.[namePath[i]];
      i++;
    }
  } catch (e) {
    fieldValue = undefined;
  }

  return fieldValue;
}

/***
 * 为对象指定路径的字段赋值
 * @param {Object} target 目标对象
 * @param {Array<string>} namePath 字段路径
 * @param {any} value 字段值
 */
export function setValueByNamePath(target, namePath, value) {
  if (!target || !namePath) return;

  let targetObject = target;
  const { length } = namePath;
  for (let i = 0; i < length; i++) {
    const field = namePath[i];
    if (i !== length - 1) {
      if (!targetObject[field]) targetObject[field] = {};
      targetObject = targetObject[field];
    } else {
      targetObject[field] = value;
    }
  }
}

export function isNotEmptyObject(obj) {
  try {
    return obj && Object.keys(obj).length > 0;
  } catch (error) {
    return false;
  }
}

export function findEmptyKeys(obj, fields) {
  try {
    Object.keys(obj).forEach((k) => {
      const v = obj[k];
      if (!v) {
        fields.push(k);
        return;
      }

      findEmptyKeys(v, fields);
    });
  } catch (error) {}
}

// 获取对象的keys
function _getObjectKeys(obj) {
  if (Array.isArray(obj)) {
    // 处理Array-link 数据
    const keys_1 = new Array(obj.length);
    for (let k = 0; k < keys_1.length; k++) {
      keys_1[k] = '' + k;
    }
    return keys_1;
  }

  if (Object.keys) return Object.keys(obj);

  const keys = [];
  for (const i in obj) {
    if (Object.hasOwnProperty(obj, i)) {
      keys.push(i);
    }
  }
  return keys;
}

/**
 * @param {Object} toCompareJSON 被比较对象
 * @param {Object} compareJSON 比较对象
 * @param {String} specificPath 值字段路径
 * @param {ChangedEntries[]} updates 变更数组
 * @returns ChangedEntries[]
 * type ChangedEntries = { path: string, value: any }
 */
function getJSONPatches({ toCompareJSON, compareJSON, specificPath = '', updates = [], shallowCompareNameKeys }) {
  // 比较基础对象/对象与被计较对象是同一个对象
  if (toCompareJSON === compareJSON) return;
  if (isNullOrUndefined(toCompareJSON) || isNullOrUndefined(compareJSON)) return;

  const objectKeys = _getObjectKeys(toCompareJSON);
  const isAnArray = Array.isArray(toCompareJSON);

  for (let i = 0; i < objectKeys.length; i++) {
    const key = objectKeys[i];
    const toCompareValue = toCompareJSON[key];
    const compareValue = compareJSON[key];
    let path;
    if (isAnArray) {
      path = !specificPath ? `[${key}]` : `${specificPath}.[${key}]`;
    } else {
      path = !specificPath ? key : [specificPath, key].join('.');
    }

    if (typeof toCompareValue !== 'object') {
      if (toCompareValue !== compareValue) {
        updates.push({
          path,
          value: compareValue,
        });
      }
      continue;
    }

    // 对于可变数据，比较数组本身，不比较数组内元素
    if (shallowCompareNameKeys.includes(key)) {
      if (JSON.stringify(toCompareValue) !== JSON.stringify(compareValue)) {
        updates.push({
          path,
          value: compareValue,
        });
      }
      continue;
    }

    getJSONPatches({
      toCompareJSON: toCompareValue,
      compareJSON: compareValue,
      specificPath: path,
      updates,
      shallowCompareNameKeys,
    });
  }
}

/**
 * 比较两个json对象，获得compareJSON 相对于toCompareJSON 的变更数组，只比较数据类型为基本类型/数组
 * 适用于比较获得preference的变更，未考虑字段为Date/Function/Symbol等对象类型的情形
 * @param {Object} params
 * @param {Object} params.toCompareJSON 被比较对象
 * @param {Object} params.compareJSON 比较对象
 * @param {String[]} params.specificPathes 指定路径, 可选参数，默认值为空数组
 * @param {String[] | Record<parentKeyName: string, nameKeys: String[]>} params.shallowCompareNameKeys 浅比较的字段，只比较到父字段，可选参数，默认值为空数组
 * 形如online_platforms/delivery_config.self_delivery_platforms 这样的可变数组(排序和长度都可能发生变化)
 * @returns ChangedEntry[]
 * type ChangedEntry = { path: string, value: any }
 */
export function diffJSON({ toCompareJSON, compareJSON, specificPathes = [], shallowCompareNameKeys = [] }) {
  const updates = [];

  if (!specificPathes.length) {
    let _nameKeys = [];
    if (Array.isArray(shallowCompareNameKeys)) {
      _nameKeys = shallowCompareNameKeys;
    } else if (typeof shallowCompareNameKeys === 'object') {
      _nameKeys = Object.values().flat();
    }

    getJSONPatches({
      toCompareJSON,
      compareJSON,
      specificPath: '',
      updates,
      shallowCompareNameKeys: _nameKeys,
    });
    return updates;
  }

  specificPathes.forEach((specificPath) => {
    const _toCompareJSON = getFieldValueByNamePath(toCompareJSON, specificPath.split('.'));
    const _compareJSON = getFieldValueByNamePath(compareJSON, specificPath.split('.'));
    let _nameKeys = [];
    if (Array.isArray(shallowCompareNameKeys)) {
      _nameKeys = shallowCompareNameKeys;
    } else if (typeof shallowCompareNameKeys === 'object') {
      _nameKeys = shallowCompareNameKeys[specificPath] || [];
    }

    if (_nameKeys.includes(specificPath) && JSON.stringify(_toCompareJSON) !== JSON.stringify(_compareJSON)) {
      updates.push({
        path: specificPath,
        value: _compareJSON,
      });
      return;
    }

    getJSONPatches({
      toCompareJSON: _toCompareJSON,
      compareJSON: _compareJSON,
      specificPath,
      updates,
      shallowCompareNameKeys: _nameKeys,
    });
  });
  return updates;
}
