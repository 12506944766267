import { actions as reportPreferenceActions } from 'src/actions/reportPreference';

const initialState = {
  isLoadingReportPreference: false,
  isLoadReportPreferenceSuccess: false,
  isLoadReportPreferenceFailed: false,

  isUpdatingReportPreference: false,
  isUpdateReportPreferenceSuccess: false,
  isUpdateReportPreferenceFailed: false,

  reportPreference: {},

  isUpdatingDiscountCategory: false,
  isUpdateDiscountCategorySuccess: false,
  isUpdateDiscountCategoryFailed: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case reportPreferenceActions.loadingReportPreference: {
      return {
        ...state,
        isLoadingReportPreference: true,
        isLoadReportPreferenceSuccess: false,
        isLoadReportPreferenceFailed: false,
      };
    }

    case reportPreferenceActions.loadReportPreferenceSuccess: {
      return {
        ...state,
        isLoadingReportPreference: false,
        isLoadReportPreferenceSuccess: true,
        isLoadReportPreferenceFailed: false,
        reportPreference: payload,
      };
    }

    case reportPreferenceActions.loadReportPreferenceFailed: {
      return {
        ...state,
        isLoadingReportPreference: false,
        isLoadReportPreferenceSuccess: true,
        isLoadReportPreferenceFailed: false,
        reportPreference: {},
      };
    }

    case reportPreferenceActions.updatingReportPreference: {
      return {
        ...state,
        isUpdatingReportPreference: true,
        isUpdateReportPreferenceSuccess: false,
        isUpdateReportPreferenceFailed: false,
      };
    }

    case reportPreferenceActions.updateReportPreferenceSuccess: {
      return {
        ...state,
        reportPreference: payload,
        isUpdatingReportPreference: false,
        isUpdateReportPreferenceSuccess: true,
        isUpdateReportPreferenceFailed: false,
      };
    }

    case reportPreferenceActions.updateReportPreferenceFailed: {
      return {
        ...state,
        isUpdatingReportPreference: false,
        isUpdateReportPreferenceSuccess: false,
        isUpdateReportPreferenceFailed: true,
      };
    }

    case reportPreferenceActions.createUpdateReportDiscountCategory: {
      return {
        ...state,
        isUpdatingDiscountCategory: true,
        isUpdateDiscountCategorySuccess: false,
        isUpdateDiscountCategoryFailed: false,
      };
    }

    case reportPreferenceActions.createUpdateReportDiscountCategorySuccess: {
      return {
        ...state,
        isUpdatingDiscountCategory: false,
        isUpdateDiscountCategorySuccess: true,
        isUpdateDiscountCategoryFailed: false,
      };
    }

    case reportPreferenceActions.createUpdateReportDiscountCategoryFailed: {
      return {
        ...state,
        isUpdatingDiscountCategory: false,
        isUpdateDiscountCategorySuccess: false,
        isUpdateDiscountCategoryFailed: true,
      };
    }

    default: {
      return state;
    }
  }
};
