var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pos-dashboard@1.20.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { SENTRY_DSN } from './src/consts/third_party';
import { name, version } from './package.json';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.STAGING_ENV,
  release: `${name}@${version}`,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
  ],
});
